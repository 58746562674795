.backToTop_button button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    height: 50px;
    width: 50px;
    font-size: 50px;
    color: #bdbdbd;
    background-color: rgb(57, 57, 57);
    border-radius: 4px;
    border-width: 0px;
    /* background-color:  */

    -webkit-animation: fadein 0.4s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.4s; /* Firefox < 16 */
     -ms-animation: fadein 0.4s; /* Internet Explorer */
      -o-animation: fadein 0.4s; /* Opera < 12.1 */
         animation: fadein 0.4s;

}

button:hover {
    /* background: #e1e1e1; */
    background-color: rgb(35, 35, 35);
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}