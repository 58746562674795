.portfolio__blog-container_article {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
    width: 300px;
    height: 150px;
    border-radius: 15px;
   
    
    background: var(--color-card);
    border: 2px solid transparent;
    transition: 0.15s;
}

.portfolio__blog-container_article:hover {
    /* background: #e1e1e1; */
    border: 2px solid #727272;
}

/* .portfolio__blog-container_article-image {

    width: 20px;
    height: 20px;

} */

.portfolio__blog-container_article-content img {

    width: 30px;
    height: 30px;
    opacity: 100%;
    

}

.portfolio__blog-container_article-content {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */

    padding: 0.7rem 0.7rem;
    height: 100%;
}

.portfolio__blog-container_article-content_options {

    justify-content: flex-end;

    
    display:grid;
    
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.1rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;



}

.portfolio__blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    /* line-height: 35px; */
    

    color: var(--color-text);
}

.portfolio__blog-container_article-content_view-project {
    justify-content: flex-end;

}
.portfolio__blog-container_article-content_view-project p{

    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    /* line-height: 35px; */
    

    color: #000000;

}

.skillsUsed {

    
    margin-top: auto;
    margin-bottom: auto;

}


.skillsUsed h4 {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 900;
    line-height: 15px;
    letter-spacing: 0.1px;
    
    margin-bottom: 0.2rem;

    color: var(--color-text);
}

.skillsUsed p {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 15px;

    color: var(--color-text);
}

.portfolio__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 18.0px;
    font-weight: 800;
    line-height: 20.0px;
    
    color: var(--color-text);
    
    cursor: pointer;
    margin-bottom: 0.5rem;
}

/* .portfolio__blog-container_article-content p:last-child {
    cursor: pointer;
} */

@media screen and (max-width: 990px) {

    .portfolio__blog-container_article {
        width: 250px;
        height: 150px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        background: var(--color-card);
        border: 2px solid transparent;
        transition: 0.15s;
    }

    .portfolio__blog-container_article-content h3 {
        font-size: 14px;
        
    }

    .skillsUsed h4 {
        font-size: 12px;
        font-weight: 600;
        
    }
    .skillsUsed p {
        font-size: 10px;
        font-weight: 400;
        
    }
}

@media screen and (max-width: 550px) {
    .portfolio__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
    .portfolio__blog-container_article-content p {
        font-size: 12px;

    }

    .skillsUsed h4 {

        font-size: 14px;
        letter-spacing: 0.5px;

    }

    .portfolio__blog-container_article {
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 20px;
        width: 200px;
    }
    
}